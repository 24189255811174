.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-screen {
  padding: 100px 36%;
  position: fixed;
  top: 195px;
  z-index: 1010;
  width: 79%;
  height: 100%;
  // background-color: #00000026;
  border-radius: 14px;

  @keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
  }
  
  .rotating {
    animation: rotating 1s linear infinite;
  }

  .text-loading {
    margin-top: 8px;
    color: #7034DA;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }
}

