.custom-dropdown-profile {
  top: 60px !important;

  .ant-dropdown-menu {
    border-radius: 16px;
  }

  p {
    margin-bottom: 0;
  }
}

.custom-dropdown-notif {
  top: 60px !important;
}

.box-header {
  background: linear-gradient(315deg, #7034DA 15.95%, #DF9BFF 100.48%);
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 120px;
  padding: 0;

  .img-box-header {
    position: absolute;
    z-index: -100;
    top: -40px;
    width: 100%;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
  }

  .box-user {
    width: 220px;
    height: 200px;
    background: #ffffff;
    opacity: 90%;
    text-align: center;
    border-right: 3px solid #d5d5d591;

    .text-flag-profile {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);

      img {
        padding-bottom: 2px;
        margin-right: 2px;
      }
    }
  }

  .box-title {
    padding-top: 30px;
    align-items: center;
    width: 100%;

    h2 {
      color: white;
      margin-bottom: 0;
    }

    span {
      color: white;

      &.end {
        color: rgb(222, 222, 222);
      }
    }
  }

  .box-profile {
    justify-content: end;
    display: flex;
    width: 22%;

    p {
      color: #ffffff;
    }
  }
}