.error-content {
  text-align: center;
  margin: 50px auto;
  max-width: 520px;

  h3 {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 25px;
  }
}