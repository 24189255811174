.box-sider {
  height: calc(100vh - 200px);
  margin-top: 200px;
  background: rgba(255, 255, 255, 0.9);
  color: #333333;
  overflow: hidden;
  z-index: 13;
  padding-top: 30px;
  border-right: 3px solid #d5d5d591;

  .ant-menu {
    background: transparent;
  }

  .ant-menu-light {
    // background: rgba(255, 255, 255, 0.9);
    background: transparent;

    .ant-menu-submenu-selected {
      font-weight: bold !important;
      color: #39009D;
    }

    .ant-menu-item-selected {
      background: #faf0ff;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      width: 95%;
      font-size: 14px;
      font-weight: normal;
      color: #39009D;
    }

    .ant-menu-item::after {
      border-right: none !important;
    }

    .ant-menu-item {
      font-size: 14px;
      font-weight: normal;

      &.ant-menu-item-active {
        color: #39009D;
      }
    }

    .ant-menu-submenu {
      font-size: 14px;
      font-weight: normal;

      &.ant-menu-submenu-active {
        color: #39009D;

        .ant-menu-submenu-title {
          color: #39009D;
        }
      }

    }
  }
}

.ant-layout-sider-children {
  overflow-y: auto;

  .img-box-sider {
    position: absolute;
    z-index: -100;
    top: -120px;
    opacity: 90%;
    background: #ffffff;
  }
}