@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
#root,
#root > * {
  height: 100%;
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

.ant-checkbox-inner {
  border-radius: 0px !important;
}

@primary-color: #38A50F;@border-radius-base: 8px;